<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import navBar from "./components/navBar";

/**
 * File-manager component
 */
export default {
  page: {
    title: "Paramètres",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, navBar },
   data() {
    return {
      title: "Stock",
       settings: [
            {
              title: "Magasins",
              route: "settings.warehouses.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bx-filter-alt",
            },
            {
              title: "Unités de mesure",
              route: "settings.uom.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bx-filter-alt",
            },

            {
              title: "Catégories des produits",
              route: "settings.product_category.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bx-pyramid",
            },
            
          ],

      items: [
        
        {
          text: "Paramètres",
          active: true,
        },
        {
          text: "Stock",
        },
      
      ],
    };
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-xl-flex">
      <div class="w-100">
        <div class="d-md-flex row">

            <!-- NavBar -->
            <div class="card filemanager-sidebar col-md-3">
                <nav-bar/>
            </div>
          <!-- filemanager-leftsidebar -->

          <div class="w-100 col-md-9">
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="row mb-3">
                    <div class="col-xl-3 col-sm-6">
                      <div class="mt-2">
                        <h5>{{title}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
             
                <div>
                    <div class="row">
                        <div class="col-lg-4" v-for="settingSection in settings" v-bind:key="settingSection.title">
                            <router-link :to="{ name: settingSection.route }">
                            <b-card
                                header-class="bg-transparent border-primary"
                                class="border border-primary text-center"
                            >
                                <template v-slot:header>
                                <h5 class="my-0 text-primary">
                                    <i class="settingIcon" :class="settingSection.icon"></i>
                                </h5>
                                </template>
                                <a>{{ settingSection.title }}</a>
                            </b-card>
                            </router-link>
                        </div>
                    </div>
                  <!-- end row -->
                </div>

               
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end w-100 -->
        </div>
      </div>

      
    </div>
    <!-- end row -->
  </Layout>
</template>
